import React from 'react'
import { Link } from 'react-router-dom'
const Contact = () => {
    return (
        <div className='h-screen'>
            <div className='ml-5 font-bold text-xl'>
                    <ul>
                        <li><Link to="/"> * </Link></li>
                        <li><Link to="/project"> Project </Link></li>
                        <li><Link to="/info"> Info</Link></li>
                    </ul>
                </div>

            <section>
                <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center ">Contact Us</h2>
                    <p className="mb-8 lg:mb-16 font-light text-center text-gray-500  sm:text-xl"> Let's connect</p>
                    <form action="#" className="space-y-8">
                        <div>
                            <label for="email" className="block mb-2 text-sm font-medium text-gray-900 ">Your email</label>
                            <input type="email" id="email" className="shadow-sm bg-gray-500 border text-white text-sm rounded-lg focus:border-primary-500 block w-full p-2.5" placeholder="name@flowbite.com" required />
                        </div>
                        <div>
                            <label for="subject" className="block mb-2 text-sm font-medium text-gray-900 ">Subject</label>
                            <input type="text" id="subject" className="block p-3 w-full text-sm text-white bg-gray-500 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 " placeholder="Let us know how we can help you" required />
                        </div>
                        <div className="sm:col-span-2">
                            <label for="message" className="block mb-2 text-sm font-medium text-gray-900 ">Your message</label>
                            <textarea id="message" rows="6" className="block p-2.5 w-full text-sm text-white bg-gray-500 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 " placeholder="Leave a comment..."></textarea>
                        </div>
                        <button type="submit" className="py-3 px-5 text-sm font-medium text-center bg-gray-500 text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300">Send message</button>
                    </form>
                </div>
            </section>
        </div>
    )
}

export default Contact